import React, { useState, useEffect, useRef, useContext } from "react"
import "./style.scss"
import { comparisonInfo as getComparisonInfo, labels, contactLenses as getContactLenses } from "./mockedData"
import CheckedIcon from "../../images/assets/Comparison/Checked.svg"
import UncheckedIcon from "../../images/assets/Comparison/Unchecked.svg"
import Breadcrumbs from "../Breadcrumbs"
import { navigate } from "gatsby"
import OrderFlow from "../OrderFlow"
import PcpItem from "../PcpItem"
import { useWindowScroll, useWindowWidth } from "../../services/hooks"
import { Modal } from "react-bootstrap"
import CartContext from "../../context/cartContext"
import ProductTag from "../ProductTag"
import useIsLifemart from '../Lifemart/hooks/useIsLifemart'
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import useAbTest from '../../utils/useAbTest'
import useIsMilitary from "../Military/hooks/useIsMilitary"
import usePromo from "../../utils/usePromo";
import {getHubblePricing} from "../../services/data";



const handleGaClick = lens => {
  let item = {}
  if (lens === "hydro") {
    item = {
      item_id: 7546170802376,
      item_name: "Hydro by Hubble Contact Lenses",
      currency: "USD",
      discount: 0,
      item_brand: "Hubble",
      item_category: "contacts",
      item_category2: "spherical",
      item_category3: "N/A",
      item_category4: "Hioxifilcon A",
      item_category5: "N/A",
      item_list_id: `Contacts`,
      item_variant: 42670112178376,
      price: 9.99,
      quantity: 1,
      index: 0,
    }
  } else {
    item = {
      item_id: 2167100637257,
      item_name: "Hubble Classic Lenses",
      currency: "USD",
      discount: 0,
      item_brand: "Hubble",
      item_category: "contacts",
      item_category2: "spherical",
      item_category3: "N/A",
      item_category4: "Methafilcon A",
      item_category5: "N/A",
      item_list_id: `Contacts`,
      item_variant: 19809451933769,
      price: 1.0,
      quantity: 1,
      index: 0,
    }
  }
  if (typeof window !== "undefined") {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        items: [item],
      },
    })
  }
}

const LensCompare = ({ isPopup, isPopupOpen, closePopup }) => {
  const { isLensesInCart } = useContext(CartContext)
  const [isAffiliate, setIsAffiliate] = useState(false)
  const [show, setShow] = useState(false)
  const [isMobile, setIsMobile] = useState(true)
  const [scrolled, setScrolled] = useState(false)
  const [fixed, setFixed] = useState(false)
  const stickyRef = useRef(null)
  const windowWidth = useWindowWidth()
  const isWindowScrolled = useWindowScroll()
  const hubblePricing = getHubblePricing()
  const comparisonInfo = getComparisonInfo(hubblePricing)
  const isLifeMart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const isPromo = usePromo()

  useEffect(() => {
    setIsAffiliate(isMilitary || isLifeMart)
  }, [isLifeMart, isMilitary])

  useEffect(() => {
    setIsMobile(windowWidth < 578)
  }, [windowWidth])

  useEffect(() => {
    const handleScroll = () => {
      const element = stickyRef.current
      const point = 64

      if (element) {
        const { top, height } = element.getBoundingClientRect()
        const { height: rowsHeight } = document
          .getElementById("header-rows")
          .getBoundingClientRect()

        if (top <= point && top >= -(height - rowsHeight - point)) {
          setScrolled(true)
          setFixed(false)
        } else if (top <= -(height - rowsHeight - point)) {
          setScrolled(false)
          setFixed(true)
        } else {
          setScrolled(false)
          setFixed(false)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isWindowScrolled])

  return (
    <>
      {isPopup ? (
        <Modal
          show={isPopupOpen}
          onHide={closePopup}
          dialogClassName="comparison-popup"
        >
          <div className="lens-comparison">
            <Modal.Header closeButton />
            <div className="grid-section">
              <div className="table-container">
                {isMobile ? (
                  <MobileTable
                    comparisonInfo={comparisonInfo}
                    stickyRef={stickyRef}
                    scrolled={scrolled}
                    fixed={fixed}
                    isLensesInCart={isLensesInCart}
                    isAffiliate={isAffiliate}
                  />
                ) : (
                  <DesktopTable
                    comparisonInfo={comparisonInfo}
                    stickyRef={stickyRef}
                    scrolled={scrolled}
                    isLensesInCart={isLensesInCart}
                    isAffiliate={isAffiliate}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <div className="lens-comparison">
          <div className="grid-section bread-crumb-grid">
            <Breadcrumbs
              links={[
                { to: "/", label: "Homepage" },
                { label: "Hubble Contact Lenses" },
              ]}
            />
          </div>
          <div className="grid-section">
            <InfoComparison setShow={setShow} />
            <div className="table-container">
              {isMobile ? (
                <MobileTable
                  comparisonInfo={comparisonInfo}
                  stickyRef={stickyRef}
                  scrolled={scrolled}
                  fixed={fixed}
                  isLensesInCart={isLensesInCart}
                  isAffiliate={isAffiliate}
                />
              ) : (
                <DesktopTable
                  comparisonInfo={comparisonInfo}
                  stickyRef={stickyRef}
                  scrolled={scrolled}
                  isLensesInCart={isLensesInCart}
                  isAffiliate={isAffiliate}
                />
              )}
            </div>
          </div>
          <OrderFlow type="contacts" show={show} setShow={setShow} />
        </div>
      )}
    </>
  )
}

const HeaderRows = ({ scrolled, fixed, isLensesInCart, isAffiliate }) => {
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const hubblePricing = getHubblePricing()
  const contactLenses = getContactLenses(!isLifemart && !isMilitary, hubblePricing)
  const banner =
    typeof document !== "undefined"
      ? document.querySelector(".discount-banner")
      : null

  return (
    <div
      className={`header-rows ${fixed && "fixed-position"} ${
        scrolled && `sticky-position ${banner ? "include-banner-height" : ""}`
      } ${!scrolled && !fixed && "static-position"}`}
      id="header-rows"
    >
      {contactLenses.map(({ title, type }, index) => {
        if (index == 0) title = "Classic by Hubble"
        if (title.includes('Hydro')) title = "Hydro by Hubble"
        if (title.includes('SkyHy')) title = "SkyHy by Hubble"
        return (
          <div className="cell" data-type={type}>
            <p className="text h6">{title}</p>
            <button
              className="text h6 buy-now-btn"
              onClick={() => {
                handleGaClick(type)
                navigate(
                  `/contact-lenses/${type}-by-hubble-lenses`, {
                    rxflow: true
                  }
                )
              }}
              disabled={(isLensesInCart)}
            >
              Buy Now
            </button>
          </div>
        )
      })}
    </div>
  )
}

const MobileTable = ({ comparisonInfo, scrolled, fixed, stickyRef, isLensesInCart, isAffiliate }) => {
  return (
    <div className="comparison-table" ref={stickyRef}>
      <HeaderRows scrolled={scrolled} isLensesInCart={isLensesInCart} isAffiliate={isAffiliate} />
      {fixed && <HeaderRows fixed={fixed} isLensesInCart={isLensesInCart} isAffiliate={isAffiliate} /> }
      <div className="content-rows">
        {Object.entries(comparisonInfo).map(
          ([key, { classic, hydro, skyhy }], index) => (
            <div className="cell" data-type={key} key={index}>
              <div className="text h9 neue-bold uppercase caption">
                {typeof labels[key] === "object" ? (
                  <>
                    {labels[key].text} <span>{labels[key].sub}</span>{" "}
                  </>
                ) : (
                  labels[key]
                )}
              </div>
              <div className="icons-wrapper">
                <div data-type={`classic ${classic ? "positive" : "negative"}`}>
                  {typeof classic === "string" && !!classic ? (
                    <div
                    className="price-info"
                    dangerouslySetInnerHTML={{ __html: classic }}
                    ></div>
                    ) : !!classic ? (
                      <CheckedIcon />
                      ) : (
                        <UncheckedIcon />
                        )}
                </div>
                <div data-type={`hydro ${hydro ? "positive" : "negative"}`}>
                  {typeof hydro === "string" && !!hydro ? (
                    <>
                      <div
                      className="price-info"
                      dangerouslySetInnerHTML={{ __html: hydro }}
                      ></div>
                      <ProductTag text='popular choice' color='purple' />
                    </>
                    ) : !!hydro ? (
                      <CheckedIcon />
                      ) : (
                    <UncheckedIcon />
                  )}
                </div>
                <div data-type={`skyhy ${skyhy ? "positive" : "negative"}`}>
                  {typeof skyhy === "string" && !!skyhy ? (
                    <>
                      <div
                        className="price-info"
                        dangerouslySetInnerHTML={{ __html: skyhy }}
                      ></div>
                      <ProductTag text='premium pick' color='orange' />
                    </>
                  ) : !!skyhy ? (
                    <CheckedIcon />
                  ) : (
                    <UncheckedIcon />
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

const DesktopTable = ({ comparisonInfo, isLensesInCart, isAffiliate }) => {
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const hubblePricing = getHubblePricing()
  const contactLenses = getContactLenses(!isLifemart && !isMilitary, hubblePricing)
  const isNewRxFlowDesktop = useNewRxFlowDesktop()
  const isPaidShipping = useAbTest()

  return (
    <>
      <table className="comparison-table desktop">
        <thead>
          <tr className="text h6">
            <th className="blank-cell"></th>
            {contactLenses.map(({ title, type, tagText, tagColor }, index) => {
              if (index == 0) title = "Classic by Hubble"
              if (title.includes('Hydro')) title = "Hydro by Hubble"
              if (title.includes('SkyHy')) title = "SkyHy by Hubble"
              return (
                <th data-type={type}>
                  {tagText && <ProductTag text={tagText} color={tagColor} />}
                  <p>{title}</p>
                  <button
                    className="buy-now-btn"
                    onClick={() => {
                      handleGaClick(type)
                      navigate(
                        `/contact-lenses/${type}-by-hubble-lenses${isNewRxFlowDesktop ? "" : "/?rxflow=true"}`
                      )
                    }}
                    disabled={isLensesInCart}
                  >
                    Buy Now
                  </button>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {Object.entries(comparisonInfo).map(
            ([key, { classic, hydro, skyhy }], index) => (
              <tr key={index}>
                <th className="text h9 neue-bold uppercase" data-type="caption">
                  {typeof labels[key] === "object" ? (
                    <>
                      {labels[key].text} <span>{labels[key].sub}</span>{" "}
                    </>
                  ) : (
                    labels[key]
                  )}
                </th>
                <th className="text h7" data-type="classic">
                  {typeof classic === "string" ? (
                    <div
                      className="price-info"
                      dangerouslySetInnerHTML={{ __html: classic }}
                    ></div>
                  ) : (
                    <CheckedIcon />
                  )}
                </th>
                <th className="text h7" data-type="hydro">
                  {typeof hydro === "string" ? (
                    <div
                      className="price-info"
                      dangerouslySetInnerHTML={{ __html: hydro }}
                    ></div>
                  ) : (
                    <CheckedIcon />
                  )}
                </th>
                <th className="text h7" data-type="skyhy">
                  {typeof skyhy === "string" ? (
                    <div
                      className="price-info"
                      dangerouslySetInnerHTML={{ __html: skyhy }}
                    ></div>
                  ) : (
                    <CheckedIcon />
                  )}
                </th>
              </tr>
            )
          )}
          {isPaidShipping &&
            <tr>
              <th className="text h9 neue-bold uppercase" data-type="caption"></th>
              <th className="text h9 asterisk" data-type="caption">
                <p className="asterisk-copy">*plus shipping & handling, and any applicable sales tax</p>
              </th>
              <th className="text h9 neue-bold uppercase" data-type="caption"></th>
              <th className="text h9 neue-bold uppercase" data-type="caption"></th>
            </tr>
          }

        </tbody>
      </table>
    </>
  )
}

const InfoComparison = () => {
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const hubblePricing = getHubblePricing()
  const contactLenses = getContactLenses(!isLifemart && !isMilitary, hubblePricing)
  return (
    <div className="info-comparison">
      <div className="pcp-items-container">
        {contactLenses.map((item, index) => (
          <PcpItem {...item} key={index} />
        ))}
      </div>
      <div className="divider"></div>
    </div>
  )
}

export default LensCompare
