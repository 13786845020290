import React, { useEffect, useContext } from "react"
import Layout from "../../components/layout"
import PcpHero from "../../components/PcpHero"
import VideoModule from "../../components/VideoModule"
import HowHubbleWorksCarousel from "../../components/HowHubbleWorksCarousel"
import ContactsCartCta from "../../components/ContactsCartCta"

import SEO from "../../components/seo"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import LensCompare from "../../components/LensCompare"
import LensesCta, { LensesCtaAdvantages } from "../../components/LensesCta"
import NewButton from "../../components/NewButton"
import CartContext from "../../context/cartContext"
import ContactsSection from "../../components/ContactsSection"

import "../../styles/pages/hubble-lenses.scss"
import Banner from "../../components/Banner";
import {getHubblePricing} from "../../services/data";

const HubbleLenses = ({ data })  => {
  const { setHeaderColor } = useContext(CartContext)
  const hubblePricing = getHubblePricing()

  const schemaMarkup = [
  {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Hubble Contacts | How We Make Contact Lenses Easy",
    "description": "At Hubble, we believe buying contact lenses should be easy. That’s why we’ve made Hubble’s personalized subscription service the easiest contacts experience in the world.",
    "thumbnailUrl": "https://i.ytimg.com/vi/w4YCLxsUxyI/hqdefault.jpg",
    "uploadDate": "2023-03-20",
    "duration": "PT1M15S",
    "contentUrl": "https://www.hubblecontacts.com",
    "embedUrl": "https://www.youtube.com/embed/w4YCLxsUxyI"

  },
  {
    "@context": "https://schema.org",
    "@url": "https://hubblecontacts.com/contact-lenses/",
    "@type": "webpage",
    "name": "Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription",
    "description": "Order contact lenses online easily with Hubble. Our high-quality and affordable prescription contact lenses get delivered directly to your door and always ship free. Try Hubble’s classic or Hydro contact lenses",
    "publisher": {
      "@type": "Organization",
      "name": "Hubble"
    }
  }
]

useEffect(() => {
  setHeaderColor("purple")

  if (typeof window !== 'undefined') {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        items: [
          {
            item_id: 2167100637257,
            item_name: 'Hubble Classic Lenses',
            currency: "USD",
            discount: 0,
            item_brand: "Hubble",
            item_category: "contacts",
            item_category2: 'spherical',
            item_category3: 'N/A',
            item_category4: 'Methafilcon A',
            item_category5: 'N/A',
            item_list_id: `Contacts`,
            price: 1.00,
            quantity: 1,
            index: 0
          },
          {
            item_id: 7546170802376,
            item_name: 'Hydro by Hubble Contact Lenses',
            currency: "USD",
            discount: 0,
            item_brand: "Hubble",
            item_category: "contacts",
            item_category2: 'spherical',
            item_category3: 'N/A',
            item_category4: 'Hioxifilcon A',
            item_category5: 'N/A',
            item_list_id: `Contacts`,
            price: 9.99,
            quantity: 1,
            index: 1
          }
        ]
      }
    })
  }
}, [])



  return (
    <Layout>
      <SEO
        title="Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription"
        description="Order contact lenses online easily with Hubble. Our high-quality and affordable prescription contact lenses get delivered directly to your door. Try Hubble’s classic, Hydro, and SkyHy contact lenses."
        schemaMarkup={schemaMarkup}
      />
      <Banner
        bannerClass="purple discount-banner promo-banner pcp extra-padding"
        bannerText="Get your first order of SkyHy or Hydro by Hubble for a special reduced price."
      />
      <PcpHero />
      <LensCompare/>
      <LensesCta product="hydro2" >
        <LensesCtaAdvantages product="hydro2" />
      </LensesCta>
      <HowHubbleWorksCarousel color="purple"/>
      <div className="text-wrapper">
        <p className="hero-text">Hubble makes ordering contact lenses online simple and affordable with our best-in-class subscription service. Shop Hubble’s selection of daily contact lenses and find your perfect fit, from our classic lenses, to our hydrating Hydro by Hubble lenses, or our new silicone hydrogel SkyHy by Hubble lenses.</p>
        <NewButton to={"/contact-lenses/classic-by-hubble-lenses"} label={"Start Your Subscription"} color="purple" />
      </div>
      <div className="video-container">
        <VideoModule
            type={"contacts"}
            video={"https://www.youtube.com/embed/NKxNYzCRGwg"}
            image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
            alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
      </div>
      <ContactsSection />
    </Layout>
  )
}

export default HubbleLenses
